import { FC, useRef } from "react";
import { useUpdateUserSettingsMutator } from "../stores/user/settings/UserSettingsMutator";
import { Button, Spinner, Table } from "react-bootstrap";
import { useUserSettingsQuery } from "../stores/user/settings/UserSettingsQueries";
import { useQueryClient } from "@tanstack/react-query";
import { InvalidateCosts } from "../stores/costs/CostMutators";
import { useTranslation } from "react-i18next";

export const UserSettings: FC = () => {

    const { t, i18n } = useTranslation()
    const { userSettings } = useUserSettingsQuery()
    const { updateUserSettingsMutator, updatingSettings } = useUpdateUserSettingsMutator()
    const queryClient = useQueryClient()
    const preferredImageStyleInput = useRef<HTMLInputElement>(null)
    const userNameInput = useRef<HTMLInputElement>(null)

    if (userSettings === undefined) {
        return <div style={{ display: "flex", alignItems: "center", flexDirection: "column", width: '90%', marginTop: "30%" }}>Loading Settings... <Spinner /></div>
    }console.log(i18n.language)
    const updateImageStyle = async (style: string) => {
        await updateUserSettingsMutator.mutateAsync({ ...userSettings, preferredImageStyle: style })
    }

    const updateName = async (name: string) => {
        await updateUserSettingsMutator.mutateAsync({ ...userSettings, userName: name })
    }

    const updateModel = async (newModel: string) => {
        await updateUserSettingsMutator.mutateAsync({ ...userSettings, openAiModelType: newModel })
        InvalidateCosts(queryClient)
    }

    const updateCulture = async (newCulture: string) => {
        await updateUserSettingsMutator.mutateAsync({...userSettings, culture: newCulture})
        console.log(newCulture)
        i18n.changeLanguage(newCulture)
        localStorage.setItem('language', newCulture)
    }

    const models = [{ value: "Fast", key: "Speed" }, { value: "Fancy", key: "Quality" }]
    const languages = [{value: "Deutsch", key: "de-DE"}, {value: "English", key: "en-US"}]
    
    return (
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column", width: '100%', }}>
            <Table>
                <tbody>
                    <tr>
                        <td><label htmlFor="userName">{t('settings.user_name')}:</label></td>
                        <td><input ref={userNameInput} type="text" name="userName" defaultValue={userSettings.userName} /></td>
                        <td><Button onClick={_ => updateName(userNameInput.current!.value)}>Update</Button></td>
                    </tr>
                    <tr>
                        <td><label htmlFor="preferredImageStyle">{t('settings.pref_img_style')}:</label></td>
                        <td><input ref={preferredImageStyleInput} type="text" name="preferredImageStyle" defaultValue={userSettings.preferredImageStyle} /></td>
                        <td><Button onClick={_ => updateImageStyle(preferredImageStyleInput.current!.value)}>Update</Button></td>
                    </tr>
                    <tr>
                        <td><label htmlFor={userSettings.openAiModelType}>{t('settings.gen_pref')}:</label></td>
                        <td colSpan={2}><select name="openAiModel" value={userSettings.openAiModelType} onChange={e => updateModel(e.target.value)}>
                            {models.map(model => <option key={model.key} value={model.key}>{model.value}</option>)}
                        </select>
                        </td>
                    </tr>
                    <tr>
                        <td><label htmlFor={userSettings.openAiModelType}>{t('settings.lang')}:</label></td>
                        <td colSpan={2}><select name="culture" value={userSettings.culture} onChange={e => updateCulture(e.target.value)}>
                            {languages.map(language => <option key={language.key} value={language.key}>{language.value}</option>)}
                        </select>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={3}>{updatingSettings ? <>Saving Changes... <Spinner /></> : <></>}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}