import { useState } from "react"
import { useUserToken } from "../../token/TokenQuery"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { GetBackendClient } from "../../../services/BackendClientFactory"
import { IntegrateUpdatedTale } from "../TaleIntegrations"
import { CharacterResource, IdResource, ProposalResource, TaleIdResource } from "../../../generated/BackendApiClient"
import { useUserSettingsQuery } from "../../user/settings/UserSettingsQueries"
import { invalidateCoins } from "../../user/resources/UserResourceMutators"

type CharacterCreationArgs = {
    taleId: TaleIdResource,
    proposal: ProposalResource
}

export const useCreateCharacterMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [creating, setCreating] = useState(false)

    const createCharacterMutator = useMutation({
        mutationFn: async (args: CharacterCreationArgs) => {
            setCreating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).createCharacter.createCharacter(args.taleId.id, args.proposal)
            invalidateCoins(queryClient)
            setCreating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { createCharacterMutator, creatingCharacter: creating }
}

type UpdateCharacter = { character: CharacterResource, taleId: TaleIdResource }
export const useUpdateCharacterMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [updating, setUpdating] = useState(false)

    const updateCharacterMutator = useMutation({
        mutationFn: async (updateCharacter: UpdateCharacter) => {
            setUpdating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).updateCharacter.updateCharacter(updateCharacter.taleId.id, updateCharacter.character)
            setUpdating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { updateCharacterMutator, updatingCharacter: updating }
}

type RemoveCharacter = { characterId: IdResource, taleId: TaleIdResource }
export const useRemoveCharacterMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [removing, setRemoving] = useState(false)

    const removeCharacterMutator = useMutation({
        mutationFn: async (removeCharacter: RemoveCharacter) => {
            setRemoving(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).removeCharacter.removeCharacter(removeCharacter.taleId.id, removeCharacter.characterId)
            setRemoving(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { removeCharacterMutator, removingCharacter: removing }
}