import { FC } from "react";
import { ActResource, CampaignResource, TaleResource } from "../generated/BackendApiClient";
import { CollectRegions } from "../dtos/RegionTreeExtensions";

export const TableOfContent: FC<{ tale: TaleResource }> = ({ tale }) => {

    const campaignContent = (campaign: CampaignResource, campaignIndex: number) => (
        <>
            <a href={"#campaign_" + campaign.id.identifier} style={{ paddingLeft: 25 }}>
                <h5>{campaignIndex + 1}. {campaign.story.name}</h5>
            </a>
            {campaign.acts.map((act, actIndex) => actContent(act, campaignIndex, actIndex))}
        </>
    )

    const actContent = (act: ActResource, campaignIndex: number, actIndex: number) => (
        <a href={"#act_" + act.id.identifier} style={{ paddingLeft: 50 }}>
            <h6>{campaignIndex + 1}.{actIndex + 1}. {act.actSummary.name}</h6>
        </a>
    )


    return (
        <div style={{ display: "flex", flexDirection: "column", fontSize: "small" }}>
            <h2>Table of Contents</h2>
            <a href="#regions" style={{ marginTop: 15 }}><h4>Regions</h4></a>
            {CollectRegions(tale.regions).map(region =>
                <a href={"#regions_" + region.name} style={{ paddingLeft: 25 }}>
                    <h5>{region.name}</h5>
                </a>
            )}
            <a href="#species" style={{ marginTop: 15 }}><h4>Species</h4></a>
            {tale.species.map(species =>
                <a href={"#species_" + species.id.identifier} style={{ paddingLeft: 25 }}>
                    <h5>{species.name}</h5>
                </a>
            )}
            <a href="#characters" style={{ marginTop: 15 }}><h4>Characters</h4></a>
            {tale.characters.map(character =>
                <a href={"#character_" + character.id.identifier} style={{ paddingLeft: 25 }}>
                    <h5>{character.name}</h5>
                </a>
            )}
            <a href="#items" style={{ marginTop: 15 }}><h4>Items</h4></a>
            {tale.items.map(item =>
                <a href={"#item_" + item.id.identifier} style={{ paddingLeft: 25 }}>
                    <h5>{item.name}</h5>
                </a>
            )}
            <a href="#campaigns" style={{ marginTop: 15 }}><h4>Campaigns</h4></a>
            {tale.campaigns.map(campaignContent)}
        </div>
    )
}