import { useMsal } from "@azure/msal-react";
import { FC, useState } from "react";
import "./SpeechBubbles.scss";
import "./LoginForm.css";
import { AuthState } from "../../dtos/AuthState";
import {
  useGoogleTokenMutator,
  useLogoutMutator,
} from "../../stores/token/TokenMutators";
import { scopes } from "../../dtos/Scopes";
import { Nav, Card, Button, Spinner } from "react-bootstrap";
import { GoogleLogin } from "@react-oauth/google";

interface LoginFormProps {
  AuthState: AuthState;
}

interface AuthTranslation {
  Authenticated: boolean;
  Authorized: boolean;
  Denied: boolean;
}

const getAuthType = (state: AuthState): AuthTranslation => {
  return {
    Authenticated: state.isAuthenticated,
    Authorized: !!state.userToken,
    Denied: !!state.userTokenError,
  };
};

const LoginForm: FC<LoginFormProps> = ({ AuthState }) => {
  const { instance } = useMsal();
  const { googleTokenMutaton } = useGoogleTokenMutator();

  const initializeMsSignIn = () => {
    instance.loginRedirect({ scopes: scopes });
  };

  const authType = getAuthType(AuthState);
  const allowLogin = !authType.Authenticated || authType.Denied;

  const loginButtons = (path: 'sign-up' | 'log-in') => {

    if (allowLogin) {
      return (
        <>
          <Button className="ms-button" onClick={initializeMsSignIn}>
            {path === 'sign-up' ? 'Sign up' : 'Log in'} with Microsoft
          </Button>
          <GoogleLogin containerProps={{ className: "google-button" }} text={path === 'sign-up' ? 'signin_with' : 'signup_with'}
            onSuccess={async (res) =>
              googleTokenMutaton.mutate(res.credential)
            }
          />
        </>
      )
    }

    if (authType.Authenticated) {
      return (
        <>
          <p>Just one more moment...</p>
          <Spinner />
        </>
      )
    }

    if (authType.Authenticated) {
      return (
        <>
          <p>All done!</p>
        </>
      )
    }
  }

  const signUpTab = "sign-up";
  const loginTab = "log-in";
  const [activeTab, setActiveTab] = useState(signUpTab);

  return (
    <div
      className="mainContainer"
      style={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/img/tabletopRpg.png')`,
      }}
    >
      <Card className="form-container">
        <Card.Header className="">
          <Nav
            onSelect={(newTab) => setActiveTab(newTab ?? signUpTab)}
            justify
            variant="tabs"
            defaultActiveKey={signUpTab}
          >
            <Nav.Item>
              <Nav.Link id={signUpTab} eventKey={signUpTab}>
                Sign up
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link id={loginTab} eventKey={loginTab}>
                Log in
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Card.Header>
        <Card.Body>
          {activeTab === signUpTab ? (
            <>
              <div className="screen-title">
                Start your Journey!
                <img alt="main logo" src="/img/MainLogo.png" className="logo" />
              </div>
              <hr className="dividing-line" />
              <div className="button-container">
                <p className="login-text">
                  Sign up for the Dungeon Tinker Beta version with an existing
                  Microsoft or Google Account
                </p>
                {loginButtons('sign-up')}
              </div>
            </>
          ) : (
            <>
              <div className="screen-title">
                Welcome Back!
                <img alt="main logo" src="/img/MainLogo.png" className="logo" />
              </div>
              <hr className="dividing-line" />
              <div className="button-container">
                <p className="login-text">
                  Log into your Dungeon Tinker Account with an existing
                  Microsoft or Google Account
                </p>
                  {loginButtons('log-in')}
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default LoginForm;
