import { FC, useState } from "react";
import { TaleImageFrame } from "../TaleImageFrame";
import { Button, Spinner } from "react-bootstrap";
import { useRemoveRegionMutator, useCreateRegionsMutator, useUpdateRegionsMutator } from "../../stores/tale/regions/RegionsMutators";
import { ProposalResource, Region, TaleIdResource } from "../../generated/BackendApiClient";
import { HasTalePermission } from "../../util/Permission";
import { RegionType, RegionTypes } from "../../dtos/RegionTreeExtensions";
import { ManualPrompt } from "../ManualPrompt";
import { ProposalGenerator } from "../ProposalGenerator";


interface TaleRegionViewProps {
    region: Region,
    taleId: TaleIdResource
}

export const TaleRegionView: FC<TaleRegionViewProps> = ({ region, taleId }) => {
    const availableTypes = RegionTypes.slice(RegionTypes.indexOf(region.regionType as RegionType) + 1);
    const [showCreateRegion, setShowCreateRegion] = useState(false)
    const createNewRegion = async (proposal: ProposalResource, regionType: RegionType) => createRegionsMutator.mutateAsync({ taleId, regionType, parentId: region.id.identifier, proposal }).then(_ => setShowCreateRegion(false))
    const { createRegionsMutator, creatingRegions } = useCreateRegionsMutator()
    const [regionType, setRegionType] = useState<RegionType>(availableTypes[0])
    const { updateRegionsMutator, updatingRegions } = useUpdateRegionsMutator()
    const { removeRegionMutator, removingRegion } = useRemoveRegionMutator()

    const [editing, setEditing] = useState<boolean>(false)

    const saveOrEditRegion = async () => {
        if (editing) {
            await updateRegionsMutator.mutateAsync({ region, taleId })
        }

        setEditing(!editing)
    }

    const removeRegion = async () => {
        await removeRegionMutator.mutateAsync({regionId: region.id, taleId})
    }

    return (
        <div id={'regions_' + region.id.identifier} style={{ display: "flex", flexDirection: "column", margin: "24px 24px 24px 0px" }}>
            {region.id.identifier && <>
                <div style={{ display: "flex", alignItems: "flex-end", marginBottom: "16px" }}>
                    <h2>{region.name} - {region.regionType}</h2>
                    <p style={{ marginBottom: "8px", marginLeft: "12px" }}>Region</p>
                <Button onClick={removeRegion} disabled={removingRegion}>x</Button>
                </div>

                <div style={{ display: "flex", flexDirection: "row", position: "relative" }}>
                    <div>
                        <div style={{ float: "right", height: "max-content", alignItems: "center", display: "flex", flexDirection: "column", margin: "0px 0px 12px 12px", backgroundColor: "#b93f45" }}>
                            <div style={{ margin: "8px", width: "max-content" }}>
                                <TaleImageFrame taleId={taleId} visualDescription={region.visualDescription} />
                            </div>
                        </div>
                        <div onBlur={(event) => region.description = event.currentTarget.innerText} suppressContentEditableWarning={true}
                            contentEditable={editing}>
                            {region.description}
                        </div>
                        {HasTalePermission(taleId, "EditTale") ? <Button disabled={updatingRegions} onClick={saveOrEditRegion}>{editing ? "Save" : "Edit Region"}</Button> : <></>}
                        {updatingRegions && <Spinner />}
                    </div>
                </div>
            </>}
            {showCreateRegion && <select value={regionType} onChange={e => setRegionType(e.target.value as RegionType)}>
                {availableTypes.map(type => <option key={type} value={type}>{type}</option>)}
            </select>}
            {showCreateRegion && <ManualPrompt onSelectedProposal={(proposal: ProposalResource) => createNewRegion(proposal, regionType)} costSelection={c => c?.regionCreation} />}
            {showCreateRegion && <h3>Generate Proposals</h3>}
            {showCreateRegion && <ProposalGenerator taleId={taleId} queryParams={{regionType: regionType}} onSelectedProposal={(proposal: ProposalResource) => createNewRegion(proposal, regionType)} costSelection={c => c?.regionCreation} type="Region" />}
            {creatingRegions ? <div>Creating Region... <Spinner /></div> : <></>}
            {HasTalePermission(taleId, "EditTale") ? <Button style={{ margin: "12px 0px 12px 0px", maxWidth: "30%" }} disabled={creatingRegions || showCreateRegion} onClick={() => setShowCreateRegion(true)}>Create new Region</Button> : <></>}
        </div>
    )
}