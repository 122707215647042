import { FC, useState } from "react";
import { TaleImageFrame } from "../TaleImageFrame";
import { Button, Spinner } from "react-bootstrap";
import { useRemoveCharacterMutator, useUpdateCharacterMutator } from "../../stores/tale/characters/CharacterMutators";
import { CharacterResource, TaleIdResource } from "../../generated/BackendApiClient";
import { HasTalePermission } from "../../util/Permission";
import { CharacterStatsView } from "../RulesetExtensions/CharacterStatsView";


interface TaleCharacterViewProps {
    character: CharacterResource,
    taleId: TaleIdResource
}

export const TaleCharacterView: FC<TaleCharacterViewProps> = ({ character, taleId }) => {

    const { updateCharacterMutator, updatingCharacter } = useUpdateCharacterMutator()
    const { removeCharacterMutator, removingCharacter } = useRemoveCharacterMutator()

    const [editing, setEditing] = useState<boolean>(false)

    const saveOrEditCharacter = async () => {
        if (editing) {
            await updateCharacterMutator.mutateAsync({ character, taleId })
        }

        setEditing(!editing)
    }

    const removeCharacter = async () => {
        await removeCharacterMutator.mutateAsync({characterId: character.id, taleId})
    }

    return (
        <div id={'character_' + character.id.identifier} style={{ display: "flex", flexDirection: "column", margin: "24px 24px 24px 0px" }}>
            <div style={{ display: "flex", alignItems: "flex-end", marginBottom: "16px" }}>
                <h2>{character.name}</h2>
                <p style={{ marginBottom: "8px", marginLeft: "12px" }}>Character</p>
                <Button onClick={removeCharacter} disabled={removingCharacter}>x</Button>
            </div>
            <CharacterStatsView taleId={taleId} character={character} />
            <div style={{ display: "flex", flexDirection: "row", position: "relative" }}>
                <div>
                    <div style={{ float: "left", height: "max-content", alignItems: "center", display: "flex", flexDirection: "column", margin: "0px 12px 12px 0px", backgroundColor: "#b93f45" }}>
                        <p style={{ textAlign: "center", fontWeight: "bold", margin: "8px" }}> {character.species} | {character.gender} | {character.homeRegionName}</p>
                        <div style={{ margin: "8px", width: "max-content" }}>
                            <TaleImageFrame taleId={taleId} visualDescription={character.visualDescription} />
                        </div>
                    </div>
                    <div onBlur={(event) => character.description = event.currentTarget.innerText} suppressContentEditableWarning={true}
                        contentEditable={editing}>
                        {character.description}
                    </div>
                    {HasTalePermission(taleId, "EditTale") ? <Button disabled={updatingCharacter} onClick={saveOrEditCharacter}>{editing ? "Save" : "Edit Character"}</Button> : <></>}
                    {updatingCharacter && <Spinner />}
                </div>
            </div>
        </div>
    )
}