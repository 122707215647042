import { FC, useState } from "react";
import { EncounterResource, IdResource, TaleIdResource } from "../../generated/BackendApiClient";
import { TaleEnemyView } from "./TaleEnemyView";
import { useRemoveEncounterMutator, useUpdateEncounterMutator } from "../../stores/tale/campaign/CampaignMutators";
import { Button, Spinner } from "react-bootstrap";
import { HasTalePermission } from "../../util/Permission";

interface TaleEncounterViewProps {
    encounter: EncounterResource
    actId: IdResource
    campaignId: IdResource
    taleId: TaleIdResource
}
export const TaleEncounterView: FC<TaleEncounterViewProps> = ({ encounter, actId, campaignId, taleId }) => {

    const { updateEncounterMutator, updatingEncounter } = useUpdateEncounterMutator()
    const { removeEncounterMutator, removingEncounter } = useRemoveEncounterMutator()

    const [editing, setEditing] = useState<boolean>(false)

    const saveOrEditEncounter = async () => {
        if (editing) {
            await updateEncounterMutator.mutateAsync({ taleId, actId, campaignId, encounter })
        }
        
        setEditing(!editing)
    }

    const removeEncounter = async () => {
        await removeEncounterMutator.mutateAsync({encounterId: encounter.id, taleId, campaignId, actId})
    }

    return (
        <div id={'encounter_' + encounter.id.identifier}>
            <div style={{ display: "flex", alignItems: "flex-end", marginBottom: "16px" }}>
                <h2>{encounter.name}</h2>
                <p style={{ marginBottom: "8px", marginLeft: "12px" }}>Encounter: {encounter.type}</p>
                <Button onClick={removeEncounter} disabled={removingEncounter}>x</Button>
            </div>
            <div>
                <h4>Goal:</h4>
                <div style={{marginBottom: "12px"}} onBlur={(event) => encounter.quest.goal = event.currentTarget.innerText} 
                suppressContentEditableWarning={true} contentEditable={editing}>
                    {encounter.quest.goal}
                </div>
                <h4>Reward:</h4>
                <div style={{marginBottom: "12px"}} onBlur={(event) => encounter.quest.reward = event.currentTarget.innerText} 
                suppressContentEditableWarning={true} contentEditable={editing}>
                    {encounter.quest.reward}
                </div>
            </div>
            <div onBlur={(event) => encounter.description = event.currentTarget.innerText} suppressContentEditableWarning={true}
                        contentEditable={editing}>
                {encounter.description}
            </div>
            <div>
                <div style={{ display: "flex", alignItems: "flex-end", marginTop: "16px" }}>
                    <h4>{encounter.location.name}</h4>
                    <p style={{ marginBottom: "8px", marginLeft: "12px" }}>Location</p>
                </div>
                <div onBlur={(event) => encounter.location.description = event.currentTarget.innerText} suppressContentEditableWarning={true}
                        contentEditable={editing}>
                    {encounter.location.description}
                </div>
            </div>
            {encounter.enemies!.map(enemy => <TaleEnemyView enemy={enemy} />)}
            {HasTalePermission(taleId, "EditTale") ? <Button disabled={updatingEncounter} onClick={saveOrEditEncounter}>{editing ? "Save" : "Edit Encounter"}</Button> : <></>}
            {updatingEncounter && <Spinner/>}
        </div>
    )
}