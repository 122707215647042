import { FC } from "react"
import "./BetaQueue.css"
import { Button, Card } from "react-bootstrap"
import { UserSettings } from "../../components/UserSettings"
import { useUserSettingsQuery } from "../../stores/user/settings/UserSettingsQueries"
import { useUserBetaStatusQuery } from "../../stores/beta/BetaQueries"
import { useLogoutMutator } from "../../stores/token/TokenMutators"


export const BetaQueue: FC = () => {
    const { userSettings } = useUserSettingsQuery();
    const { userBetaStatus } = useUserBetaStatusQuery()
    const { logoutMutation } = useLogoutMutator()

    return (
        <div className="beta-queue"
            style={{
                backgroundImage: `url('${process.env.PUBLIC_URL}/img/tabletopRpg.png')`,
            }}>

            <div className='queue-frame'>
                <Card>
                    <Card.Header className="card-header"><h3>In Queue</h3></Card.Header>
                    <Card.Body className="queue-container">
                        <div className="screen-title">
                            <p className="welcome-message">
                                {userSettings?.userName} <br />
                                waiting to be unlocked...
                            </p>
                            <img alt="main logo" src="/img/MainLogo.png" className="logo" />
                        </div>
                        <hr className="dividing-line" />
                        <div className="text-container">
                            <p className="thank-you-text">Thank you for applying to our Beta!</p>
                            <p className='info-text'>
                                You will be notified via e-mail once your account has been unlocked.
                            After that, the next time you login, you'll be entering the beta instead of this screen.
                        </p>
                            <div>
                                <p className="queue-pos-text">
                                    Your current position in queue: {userBetaStatus?.position}</p>
                            </div>
                        </div>
                        <Button style={{ width: "100px" }} onClick={() => logoutMutation.mutate()}>Back</Button>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}