import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { GetBackendClient } from "../../../services/BackendClientFactory"
import { useUserToken } from "../../token/TokenQuery"
import { IntegrateUpdatedTale } from "../TaleIntegrations"
import { useQueryClient } from '@tanstack/react-query'
import { ActResource, CampaignResource, EncounterResource, IdResource, ProposalResource, TaleIdResource } from "../../../generated/BackendApiClient"
import { useUserSettingsQuery } from "../../user/settings/UserSettingsQueries"
import { invalidateCoins } from "../../user/resources/UserResourceMutators"

export const useCreateCampaignMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [creating, setCreating] = useState(false)

    const createCampaignMutator = useMutation({
        mutationFn: async (taleId: TaleIdResource) => {
            setCreating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).createCampaign.createCampaign(taleId.id)
            invalidateCoins(queryClient)
            setCreating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { createCampaignMutator, creatingCampaign: creating }
}

type UpdateCampaign = { taleId: TaleIdResource, campaign: CampaignResource }
export const useUpdateCampaignMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [updating, setUpdating] = useState(false)

    const updateCampaignMutator = useMutation({
        mutationFn: async (updateCampaign: UpdateCampaign) => {
            setUpdating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).updateCampaign.updateCampaign(updateCampaign.taleId.id, updateCampaign.campaign)
            setUpdating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { updateCampaignMutator, updatingCampaign: updating }
}

type ActCreation = { taleId: TaleIdResource, campaignId: IdResource }
export const useCreateActsMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [creating, setCreating] = useState(false)

    const createActsMutator = useMutation({
        mutationFn: async (actCreation: ActCreation) => {
            setCreating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).createActs.createActs(actCreation.taleId.id, actCreation.campaignId.identifier)
            invalidateCoins(queryClient)
            setCreating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { createActsMutator, creatingActs: creating }
}

type UpdateAct = { taleId: TaleIdResource, campaignId: IdResource, act: ActResource }
export const useUpdateActMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [updating, setUpdating] = useState(false)

    const updateActMutator = useMutation({
        mutationFn: async (updateAct: UpdateAct) => {
            setUpdating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).updateAct.updateAct(updateAct.taleId.id, updateAct.campaignId.identifier, updateAct.act)
            setUpdating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { updateActMutator, updatingAct: updating }
}

type EncounterCreation = { taleId: TaleIdResource, campaignId: IdResource, actId: IdResource, encounterType: string, proposal: ProposalResource }
export const useCreateEncounterMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [creating, setCreating] = useState(false)

    const createEncounterMutator = useMutation({
        mutationFn: async (encounterCreation: EncounterCreation) => {
            setCreating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).createEncounter.createEncounter(encounterCreation.taleId.id, encounterCreation.campaignId.identifier, encounterCreation.actId.identifier, encounterCreation.encounterType, encounterCreation.proposal)
            invalidateCoins(queryClient)
            setCreating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { createEncounterMutator, creatingEncounter: creating }
}

type UpdateEncounter = { taleId: TaleIdResource, campaignId: IdResource, actId: IdResource, encounter: EncounterResource }
export const useUpdateEncounterMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [updating, setUpdating] = useState(false)

    const updateEncounterMutator = useMutation({
        mutationFn: async (updateEncounter: UpdateEncounter) => {
            setUpdating(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).updateEncounter.updateEncounter(updateEncounter.taleId.id, updateEncounter.campaignId.identifier, updateEncounter.actId.identifier, updateEncounter.encounter)
            setUpdating(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { updateEncounterMutator, updatingEncounter: updating }
}

type RemoveCampaign = { campaignId: IdResource, taleId: TaleIdResource }
export const useRemoveCampaignMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [removing, setRemoving] = useState(false)

    const removeCampaignMutator = useMutation({
        mutationFn: async (removeCampaign: RemoveCampaign) => {
            setRemoving(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).removeCampaign.removeCampaign(removeCampaign.taleId.id, removeCampaign.campaignId)
            setRemoving(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { removeCampaignMutator, removingCampaign: removing }
}

type RemoveAct = { actId: IdResource, taleId: TaleIdResource, campaignId: IdResource }
export const useRemoveActMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [removing, setRemoving] = useState(false)

    const removeActMutator = useMutation({
        mutationFn: async (removeAct: RemoveAct) => {
            setRemoving(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).removeAct.removeAct(removeAct.taleId.id, removeAct.campaignId.identifier, removeAct.actId)
            setRemoving(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { removeActMutator, removingAct: removing }
}

type RemoveEncounter = { encounterId: IdResource, taleId: TaleIdResource, campaignId: IdResource, actId: IdResource }
export const useRemoveEncounterMutator = () => {

    const queryClient = useQueryClient()
    const { userToken } = useUserToken()
    const { userSettings } = useUserSettingsQuery()
    const [removing, setRemoving] = useState(false)

    const removeEncounterMutator = useMutation({
        mutationFn: async (removeEncounter: RemoveEncounter) => {
            setRemoving(true)
            const updatedTale = await GetBackendClient(userToken, userSettings).removeEncounter.removeEncounter(removeEncounter.taleId.id, removeEncounter.campaignId.identifier, removeEncounter.actId.identifier, removeEncounter.encounterId)
            setRemoving(false)
            return updatedTale.data
        },
        onSuccess: IntegrateUpdatedTale(queryClient)
    })

    return { removeEncounterMutator, removingEncounter: removing }
}