import { Region, RegionTree, RegionTreeNode } from "../generated/BackendApiClient";


export const CollectRegions = (tree: RegionTree) => {
    if (!tree.root) {
        return []
    }
    return CollectRegionsFromNode(tree.root);
}

const CollectRegionsFromNode = (node: RegionTreeNode): Region[] => {
    return [node.region, ...node.children.flatMap(child => CollectRegionsFromNode(child))];
}

export type RegionType = 'universe' | 'galaxy' | 'solarsystem' | 'celestialbody' | 'continent' | 'country' | 'area' | 'poi' | 'segment'
export const RegionTypes: RegionType[] = [
    'universe',
    'galaxy',
    'solarsystem',
    'celestialbody',
    'continent',
    'country',
    'area',
    'poi',
    'segment'
];