import { FC, useRef, useState } from "react";
import { User } from "../../util/TokenDecoder";
import { Button, Card, Spinner } from "react-bootstrap";
import { useSendBetaEnlistRequestMutator } from "../../stores/user/BetaAccessMutator";
import './RequestBetaAccess.css';
import { useLogoutMutator } from "../../stores/token/TokenMutators";
import { useUserSettingsQuery } from "../../stores/user/settings/UserSettingsQueries";

export const RequestBetaAcces: FC<{ user: User }> = ({ user }) => {

    const { userSettings } = useUserSettingsQuery()
    const { sendBetaEnlistRequestMutator, sending } = useSendBetaEnlistRequestMutator()
    const { logoutMutation } = useLogoutMutator()
    const userEmailInput = useRef<HTMLInputElement>(null)
    const [hasSent, setHasSent] = useState(false)

    const sendRequest: React.FormEventHandler<HTMLFormElement> = async e => {
        e.preventDefault()

        if (!userEmailInput.current) {
            return
        }

        await sendBetaEnlistRequestMutator.mutateAsync(userEmailInput.current.value)
        setHasSent(true)
    }

    return (
        <div className="main-container"
            style={{
                backgroundImage: `url('${process.env.PUBLIC_URL}/img/tabletopRpg.png')`,
            }}>

            <div className='request-frame'>
                <Card>
                    <Card.Header className="card-header"><h3>Request Access</h3></Card.Header>
                    <Card.Body className="request-container">
                        <div className="screen-title">
                            <p className="welcome-message">
                                Embark on this <br />
                                adventure with us!
                            </p>
                            <img alt="main logo" src="/img/MainLogo.png" className="logo" />
                        </div>
                        <hr className="dividing-line" />
                        <p className = 'info-text' style={{ textAlign: "justify" }}>
                            If you'd like to apply for the Dungeon Tinker Closed Beta, you can request access for free here.
                            Your email will only be used to notify you once your request is approved and
                            will be deleted immediately afterwards, and we won't send you any other messages.<br />
                        </p>
                        <form className="request-form" onSubmit={sendRequest}>
                                <input id = "e-mail-input" disabled={hasSent} ref={userEmailInput} type="email" name="email" placeholder="E-Mail" required />
                            <Button disabled={hasSent} className="slim-btn" type="submit">Apply for Beta</Button>
                        </form>
                        {hasSent && <p>You have sucessfully applied for the beta.</p>}
                        {sending && <Spinner />}

                        <div className="already-confirmed-text">
                            <p className = 'info-text'> 
                                If your access has been confirmed and you still see this screen click 
                            </p>
                            <Button id = "click-here-btn" className="slim-btn" onClick={() => window.location.reload()}>Here</Button>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}